@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

body {
  font-family: Arial;
  color: #666666;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12px;
}

a {
  color: rgb(17, 85, 204);
  text-decoration: underline;
}

h1 {
  font-size: '32px';
  font-weight: 700;
  color: #000000;
}

h2 {
  color: #0b5394;
  font-size: 16px;
  font-weight: 700;
}

h3 {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 0px;
}

h4 {
  font-size: 12px;
  color: #0b5394;
  font-weight: 700;
}

h5 {
  font-size: 12px;
  color: #666666;
  font-weight: 700;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.color {
  color: #5B8AB7;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
